import { CodeSurfer } from "code-surfer";
import { Stage as BoxStage } from "../../../../../../components/Box";
import { Stage as StatueStage } from "../../../../../../components/Statue";
import { Stage as DetailsStage } from "../../../../../../components/Details";
import React from 'react';
export default {
  CodeSurfer: CodeSurfer,
  BoxStage: BoxStage,
  StatueStage: StatueStage,
  DetailsStage: DetailsStage,
  React: React
};