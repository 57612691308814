import "core-js/modules/es6.array.is-array";
import _toConsumableArray from "/zeit/51bfdab2/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.array.some";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.object.freeze";
import _slicedToArray from "/zeit/51bfdab2/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _classCallCheck from "/zeit/51bfdab2/node_modules/@babel/runtime/helpers/esm/classCallCheck";
import { useState, useRef, useMemo, useEffect } from 'react';
import { debounce } from 'debounce';

function useMeasure() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    debounce: 0,
    scroll: false
  },
      debounce$1 = _ref.debounce,
      scroll = _ref.scroll,
      polyfill = _ref.polyfill;

  var ResizeObserver = polyfill || (typeof window === 'undefined' ? function ResizeObserver() {
    _classCallCheck(this, ResizeObserver);
  } : window.ResizeObserver);

  if (!ResizeObserver) {
    throw new Error('This browser does not support ResizeObserver out of the box. See: https://github.com/react-spring/react-use-measure/#resize-observer-polyfills');
  }

  var _useState = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    bottom: 0,
    right: 0,
    x: 0,
    y: 0
  }),
      _useState2 = _slicedToArray(_useState, 2),
      bounds = _useState2[0],
      set = _useState2[1]; // keep all state in a ref


  var state = useRef({
    element: null,
    scrollContainers: null,
    resizeObserver: null,
    lastBounds: bounds
  }); // set actual debounce values early, so effects know if they should react accordingly

  var scrollDebounce = debounce$1 ? typeof debounce$1 === 'number' ? debounce$1 : debounce$1.scroll : null;
  var resizeDebounce = debounce$1 ? typeof debounce$1 === 'number' ? debounce$1 : debounce$1.resize : null; // memoize handlers, so event-listeners know when they should update

  var _useMemo = useMemo(function () {
    var callback = function callback() {
      if (!state.current.element) return;

      var _state$current$elemen = state.current.element.getBoundingClientRect(),
          left = _state$current$elemen.left,
          top = _state$current$elemen.top,
          width = _state$current$elemen.width,
          height = _state$current$elemen.height,
          bottom = _state$current$elemen.bottom,
          right = _state$current$elemen.right,
          x = _state$current$elemen.x,
          y = _state$current$elemen.y;

      var size = {
        left: left,
        top: top,
        width: width,
        height: height,
        bottom: bottom,
        right: right,
        x: x,
        y: y
      };
      Object.freeze(size);
      if (!areBoundsEqual(state.current.lastBounds, size)) set(state.current.lastBounds = size);
    };

    return [resizeDebounce ? debounce(callback, resizeDebounce) : callback, scrollDebounce ? debounce(callback, scrollDebounce) : callback];
  }, [set, scrollDebounce, resizeDebounce]),
      _useMemo2 = _slicedToArray(_useMemo, 2),
      resizeChange = _useMemo2[0],
      scrollChange = _useMemo2[1]; // cleanup current scroll-listeners / observers


  function removeListeners() {
    if (state.current.scrollContainers) {
      state.current.scrollContainers.forEach(function (element) {
        element.removeEventListener('scroll', scrollChange, true);
      });
      state.current.scrollContainers = null;
    }

    if (state.current.resizeObserver) {
      state.current.resizeObserver.disconnect();
      state.current.resizeObserver = null;
    }
  } // add scroll-listeners / observers


  function addListeners() {
    if (!state.current.element) {
      return;
    }

    state.current.resizeObserver = new ResizeObserver(scrollChange);
    state.current.resizeObserver.observe(state.current.element);

    if (scroll && state.current.scrollContainers) {
      state.current.scrollContainers.forEach(function (scrollContainer) {
        scrollContainer.addEventListener('scroll', scrollChange, {
          capture: true,
          passive: true
        });
      });
    }
  } // the ref we expose to the user


  var ref = function ref(node) {
    if (!node || node === state.current.element) {
      return;
    }

    removeListeners();
    state.current.element = node;
    state.current.scrollContainers = findScrollContainers(node);
    addListeners();
  }; // add general event listeners


  useOnWindowScroll(scrollChange, Boolean(scroll));
  useOnWindowResize(resizeChange); // respond to changes that are relevant for the listeners

  useEffect(function () {
    removeListeners();
    addListeners();
  }, [scroll, scrollChange, resizeChange]); // remove all listeners when the components unmounts

  useEffect(function () {
    return removeListeners;
  }, []);
  return [ref, bounds];
} // Adds native resize listener to window


function useOnWindowResize(onWindowResize) {
  useEffect(function () {
    var cb = onWindowResize;
    window.addEventListener('resize', cb);
    return function () {
      window.removeEventListener('resize', cb);
    };
  }, [onWindowResize]);
}

function useOnWindowScroll(onScroll, enabled) {
  useEffect(function () {
    if (enabled) {
      var cb = onScroll;
      window.addEventListener('scroll', cb, {
        capture: true,
        passive: true
      });
      return function () {
        return window.removeEventListener('scroll', cb, true);
      };
    }
  }, [onScroll, enabled]);
} // Returns a list of scroll offsets


function findScrollContainers(element) {
  var result = [];
  if (!element || element === document.body) return result;

  var _window$getComputedSt = window.getComputedStyle(element),
      overflow = _window$getComputedSt.overflow,
      overflowX = _window$getComputedSt.overflowX,
      overflowY = _window$getComputedSt.overflowY;

  if ([overflow, overflowX, overflowY].some(function (prop) {
    return prop === 'auto' || prop === 'scroll';
  })) result.push(element);
  return [].concat(result, _toConsumableArray(findScrollContainers(element.parentElement)));
} // Checks if element boundaries are equal


var keys = ['x', 'y', 'top', 'bottom', 'left', 'right', 'width', 'height'];

var areBoundsEqual = function areBoundsEqual(a, b) {
  return keys.every(function (key) {
    return a[key] === b[key];
  });
};

if (typeof module !== 'undefined' && Object.getOwnPropertyDescriptor && Object.getOwnPropertyDescriptor(module, 'exports').writable) {
  module.exports = useMeasure;
}

export default useMeasure;